@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/mixins/fonts-2024';
.title_container {
	margin-inline: auto;
	max-width: 800px;
	color: #000;
	margin-bottom: 60px;

	.faq {
		padding-top: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid rgb(0 0 0 / 10%);

		img {
			bottom: auto;
			top: 0;
		}

		p {
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}

	p.question {
		margin-top: 0;
		font-size: 22px;
		margin-bottom: 0;
		padding-right: 35px;
		line-height: 1.4;
	}
}
.contact_text {
	@include headline7;
	@include xs-only {
		@include body5;
	}
}

.contact_cta {
	display: flex;
	align-items: center;
	gap: 1.75rem;
	.phone_link {
		text-decoration: none;
		color: #fff;
	}
	small {
		font-size: 0.875rem;
	}
}
