@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';

.container {
	display: flex;
	background: black;
	color: white;

	text-align: left;

	@include sm-down {
		flex-direction: column;

		> div {
			flex: 1 0 100%;
		}

		h2 {
			margin-bottom: 50px;
			text-align: center;
		}
	}
}

.inverted {
	background: white;
	color: black;
}

.title {
	flex: 0 1 25%;
}

.faqs {
	flex: 1 0 75%;
}

.question_container {
	font-size: 16px;

	&:not(:last-child) {
		padding-bottom: 50px;
	}

	.answer {
		padding-right: 70px;
		padding-top: 20px;
		margin: 0;
		display: block;

		@include sm-down {
			@include pod-3-header-9-14;
			font-weight: 400;
		}

		a {
			cursor: pointer;
		}
		li:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	:global(.arrow_slide_down_down),
	:global(.arrow_slide_down_up) {
		top: 0;
		bottom: auto;
	}

	ul {
		padding-left: 2rem;
	}
}

.question {
	padding-right: 70px;
	@include pod-3-header-6;
	@include sm-down {
		@include pod-3-header-9-14;
	}
}
