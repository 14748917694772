@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $black;
	color: $white;
	display: grid;

	grid-template-columns: repeat(2, minmax(0, 1fr));

	align-items: center;

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
	}
}

.image_container {
	position: relative;
	height: 100%;
	width: 100%;

	max-height: 45rem;

	background: seashell;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}
}

.cta_container {
	padding: 12rem 6rem;
	@include sm-down {
		padding: 4rem 1.25rem 3rem;
	}
}

.cta_container > h2 {
	@include headline4;
	font-size: 2.75rem; // TODO: not in spec

	margin-bottom: 1rem;

	@include sm-down {
		font-size: 2.25rem; // TODO: not in spec
		margin-bottom: 1.25rem;
	}
}

.cta_container > p {
	@include body3;
	max-width: 40ch;

	margin-bottom: 2.25rem;

	@include sm-down {
		margin-bottom: 1.5rem;
	}
}
