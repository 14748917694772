@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/colors-2023';

.container {
	color: black;

	&.inverted {
		color: white;

		.cta_button > a {
			color: white;

			span:empty,
			span::before,
			span::after {
				background-color: white !important;
			}
		}
	}
}

.desktop_container {
	@include sm-down {
		display: none;
	}
}

.mobile_container {
	display: none;
	@include sm-down {
		display: unset;
	}
}

.cta_button {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.desktop_list {
	list-style: none;
	display: flex;
	justify-content: space-between;
	align-items: center;

	max-width: 60rem;
	margin-inline: auto;
}

.logo_container {
	display: flex;
	justify-content: center;
	align-items: center;

	.button {
		opacity: 0.28;

		&:hover,
		&:focus-visible {
			opacity: 1;
		}

		.logo {
			> svg {
				height: clamp(1rem, 6.5vw, 2rem);
				width: unset;
				max-height: unset;
				max-width: unset;
			}
		}
	}
}

.button_active {
	opacity: 1;
}

.quote {
	text-align: center;
	max-width: 29ch;
	margin-inline: auto;

	margin-bottom: 4rem;
}

.mobile_slide {
	margin-bottom: 0;
}

.quote_active {
	opacity: 1;
}
