@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
}

.error {
	color: $ui-velvet-red;
	margin-bottom: 1.25rem;
	font-size: 14px;
}

.card_element {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
}

.disclaimer {
	@include body8;
	opacity: 0.7;
}

@mixin card-element-base {
	border: none;
	border-radius: 6px;
	box-shadow: #d9d9d9 0 0 0 1px;
	width: 100%;

	@include subheading5;
	padding: 0.75rem;
	color: $neutral-black;

	&::placeholder {
		font: inherit;
		color: #adb3c5;
	}
}

.card_number {
	grid-column: 1 / span 2;
	@include card-element-base;
	transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
}

.card_cvc {
	grid-column: 1;
	@include card-element-base;
	transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
}

.card_expiry {
	grid-column: 2;
	@include card-element-base;
	transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
}

$--focusBoxShadow: 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);

.focus {
	outline: 0;
	border-color: hsla(210, 96%, 45%, 50%);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), $--focusBoxShadow;
}

.billing_shell {
	transition: all 0.15s ease;
}

.billing_inner {
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr 1fr;
}

.billing_country {
	padding: 0.75rem;
	border-radius: 6px;
	box-shadow: #d9d9d9 0 0 0 1px;

	select {
		border: none;
		@include body6;
		width: 100%;
		padding: 0;
		margin: 0;
	}
}

.billing_postal {
	padding: 0.75rem;
	border-radius: 6px;
	box-shadow: #d9d9d9 0 0 0 1px;

	@include body6;
}
