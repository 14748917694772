@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: center;
	column-gap: 30px;
	margin-top: 80px;

	img {
		max-height: 245px;
		min-height: 200px;
	}

	p {
		@include body1;
		margin: 0 0 20px;
		font-weight: 500;

		@include sm-down {
			font-size: 18px;
		}
	}

	span {
		@include headline3;
		display: block;
		margin: 20px 0 0;

		@include sm-down {
			font-size: 48px;
		}
	}

	> div {
		border-radius: 25px;
		background: rgba(255, 255, 255, 0.15);
		padding: 48px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		row-gap: 20px;

		&:nth-child(1) > span {
			color: #246aff;
		}

		&:nth-child(2) > span {
			color: #24ffbd;
		}

		&:nth-child(3) > span {
			color: #faa845;
		}

		@include sm-down {
			max-width: 341px;
			margin: 0 auto;
			padding: 24px;
		}
	}

	@include sm-down {
		grid-template-columns: 1fr;
		column-gap: 0;
		row-gap: 30px;
		margin-top: 50px;
	}
}

.header {
	text-align: center;

	h2 {
		max-width: 12ch;
		margin-inline: auto;
	}

	p {
		color: #a6a6a6;
		max-width: 40ch;
		margin-inline: auto;
	}
}
