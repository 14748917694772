@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
}
.content {
	padding: 120px 0;
	@include md-down {
		padding: 2rem 0;
	}
	display: flex;
	gap: 120px;
	@include md-down {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	h2,
	h3 {
		scroll-margin: 80px;
	}

	h2 {
		@include headline4;
		@include md-down {
			@include headline5;
			margin-bottom: 2rem;
		}
		margin-bottom: 2rem;
		max-width: 23ch;
	}
	hr {
		color: #a6a6a6;
		margin-bottom: 2.5rem;
	}
	h3 {
		@include headline6;
		@include md-down {
			@include headline7;
			margin-bottom: 2rem;
		}
		margin-bottom: 2rem;
	}
	p {
		@include body1;
		@include md-down {
			@include body2;
			margin-bottom: 1.4rem;
		}
		margin-bottom: 1.4rem;
	}
	li {
		@include body1;
		@include md-down {
			@include body2;
			margin-bottom: 1rem;
			padding-left: 2rem;
		}
		margin-bottom: 1rem;
		padding-left: 2rem;
	}
	li:last-child {
		margin-bottom: 0;
	}
	ol,
	ul {
		padding-left: 1.5rem;
		margin-bottom: 3rem;
		strong {
			font-weight: 700;
		}
	}
	nav {
		margin-left: 1rem;
		@include headline8;
		border-left: 1px solid black;
		//max-width: 550px;
	}
}
.hero_img {
	position: relative;
	img {
		height: 100vh;
		object-fit: cover !important;
	}
	@include xs-only {
		height: 50vh;
		img {
			width: unset;
		}
	}
}
.main_title {
	@include headline3;
	color: $white;
	width: 21ch;
	max-width: 85vw;
	text-align: center;
	display: flex;
	justify-content: center;
	@include xs-only {
		@include headline6;
		color: $white;
		width: 21ch;
		max-width: 85vw;
		text-align: center;
	}
}
.hero_chevron {
	position: absolute;
	cursor: pointer;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -220%);
	border-radius: $br-circle;

	display: flex;
	justify-content: center;

	padding: 0.5rem;
	background: rgba(255, 255, 255, 0.15);

	> span {
		display: flex;
	}
}
.hero_chevron:hover {
	backdrop-filter: blur(9px);
	background: rgba(255, 255, 255, 0.15);
}
.subheader_float {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	gap: 2rem;
	@include xs-only {
		margin: 2rem 0;
	}
}

.toc_line {
	border-left: 3px solid transparent;
	padding-left: 1.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 400px;
	a {
		color: $black;
	}
}
.toc_line_active {
	border-left: 3px solid #1862ff;
	color: #1862ff;
	a {
		color: #1862ff;
	}
}
.toc_link {
	cursor: pointer;
	text-align: left;
	font-family: inherit;
	@include headline8;
}
.toc_level_2 {
	padding-left: 1.5rem;
}
.toc_level_3 {
	padding-left: 3rem;
}
.toc_level_4 {
	padding-left: 4.5rem;
}
.toc_container {
	position: sticky;
	top: 120px;
	padding-right: 2rem;
	overflow-y: auto;
	min-width: 400px;
	max-height: calc(100vh - 120px);
}
.left_panel {
	padding-left: 120px;
	@include md-down {
		display: none;
	}
}
.right_panel {
	max-width: 767px;
	@include md-down {
		widht: 100%;
		padding: 0rem 1rem;
	}
}
.toc_h_wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.875rem;
	margin-top: 0;
}
.toc_h_wrapper_collapsed {
	display: none;
}

.contact_card {
	width: 100%;
	background-color: #f2f2f2;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 473px;
	text-align: center;
	a {
		color: $black;
		text-decoration: underline;
	}
	strong {
		font-weight: 500;
	}
	h2 {
		@include headline6;
		@include md-down {
			@include headline7;
			margin-bottom: 2rem;
		}
		margin-bottom: 2rem;
	}
}

.collapse_icon {
	> span {
		display: flex;
	}
}
